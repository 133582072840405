import React from 'react';

function Admin() {

    return (
        <>
        <p>Server Management</p>
        <ul>
        <li>Systems Administrator of Linux servers (Ubuntu and Debian) with special reference to firewall system security, database administration and fault tolerance.</li>
        <li>School IT Support for 18 months using Windows Server & Active Directory – implemented new backup system and supported 50+ Desktop Users using Group Policies.
        Hardware and Software support of colleagues, with emphasis on troubleshooting and teaching.</li>
        <li>MS Server Experience in versions 2012, 2016, 2022 and Small Business Server (SBS)</li>
        <li>Installed, configured and maintained Dell PowerEdge Servers with RAID redundancy</li>
        <li>Managed and deployed AWS Systems in Elastic Cloud Computing (EC2) and middleware through Elastic Beanstalk</li>

        </ul>
        <p>Database Administration</p>
        <ul>
        
        <li>MS SQl-Server Experience versions from 6.5 through to 2022</li>
        <li>MySQL Experience from 3.21 to 8.0</li>
        <li>Extensive experience in configuring ODBC connections from Desktop Applications to Database Servers</li>
        <li>Developed Glueware in Microsoft Access to link legacy systems through both flatfile data exchange and ODBC</li>
        <li>High proficiency in Visual Basic for Applications (VBA), enabling the creation of add-ons for Microsoft Office applications to 
            connect to external datasources.</li>
        </ul>
        <p>Desktop Administration and Deployment</p>
        <ul>
            <li>Created remote deployments for Mercer Desktops worldwide</li>
            <li>Supported and maintained endpoints in Windows (all versions), OSX (from Snow Leopard through to Sonoma) and Linux (Gnome, Xfce, Mate, KDE and Unity)</li>
          
        </ul>
        <p>Technical Support Experience</p>
        <ul>
        <li>Delivery of Desktop Support to 50-Seat Windows deployment including hardware upgrades and maintenance, 
            remote software deployment and security through Active Directory Group Policies</li>
        <li>Installation, management and maintenance of Church AV and livestream systems including data projectors and TVs</li>
        <li>Management of TCP/IP network infrastructure including DNS, DHCP, NAT and Firewall configuration</li>
        </ul>
        </>
    )

}

export default Admin;