import React from 'react';

function Youtube({ url }) {
  // Function to extract the video ID from the URL
  const extractVideoID = (url) => {
    const urlParams = new URLSearchParams(new URL(url).search);
    return urlParams.get('v');
  };

  const videoID = extractVideoID(url);
  const embedUrl = `https://www.youtube.com/embed/${videoID}`;

  return (
    <div className="video-responsive">
      <iframe
        width="95%"
        height="100%"
        src={embedUrl}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
}


export default Youtube;