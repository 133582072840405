import React, { useState } from 'react';
import Youtube from './youtube';
import PDFViewer from './pdf';

const DemoLink = ({ text, demoLocation }) => {
    const [showImage, setShowImage] = useState(false);
  
    // Function to toggle the image display
    const handleMouseEnter = () => {
      setShowImage(true);
    };
  
    const handleMouseLeave = () => {
      setShowImage(false);
    };
  
    return (
      <div>
        <p onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          {text}
        </p>
        {showImage && <img src={demoLocation} alt="Demo" className='website-demo'/>}
      </div>
    );
  };


function Dev({onImageClick}) {


  const schoolLibraryDoc = './assets/School-Library-User-Manual.pdf';

    return (
        <>
        <hr></hr>
        <p>This entire portfolio was created in React Native rather than a content management system to illustrate my website development skills.</p>
        <div className="information-card-title">CodeMonkey Design Ltd</div>
        <p>CodeMonkey Design Ltd is my own web design and development company. It has a number of clients ranging
        from Churches to Nightclubs, Fitness Clubs and Restaurants. I work mainly in Wordpress and develop custom plug-ins and ACF for
        my clients in order to meet their needs. I am skilled in implementing Woocommerce, Shopify and Payment/Donation Gateways. Hover over each name to see an illustration.</p>
        <DemoLink text="S. Andrew's Church, Leicester" demoLocation="./assets/S.-Andrew-Jarrom-Street-Demo.gif" />
        <DemoLink text="Holy Nativity, Mixenden, Halifax" demoLocation="./assets/Holy-Nativity-Demo.gif" />
        <DemoLink text="Plates, Plymouth" demoLocation="./assets/Plates-Demo-30-07-2023_1.gif" />
        <DemoLink text="Empower Dance and Fitness, Plymouth" demoLocation="./assets/Empower-Demo_1.gif" />
        <div className="information-card-title">Find Me A Priest</div>
        <p><span className="orange-text">2024 Church of England</span></p>
        <p>A ReactJS Frontend and NodeJS Backend Single Page Application (SPA) to support Parishes in the Church of England.
           This application matches a sophisticated range of criteria and draws information not only from its own MySQL Database
           but interfaces with Google Routes API to determine the distance of the Priest available from the Church.</p>
        <Youtube url='https://www.youtube.com/watch?v=lvHWBQ5rvK0' />
        <div className="information-card-title">Dance Diary</div>
        <p><span className="orange-text">2024 Empower Dance and Fitness, Plymouth</span></p>
        <p>A ReactJS Frontend and PHP/MySQL Backend employed to create a visually attractive yet lightweight mobile-friendly Diary
           application. The version illustrated is skinned for a Dance Studio but can be employed wherever bookings are taken
           for multiple people</p>
        <Youtube url="https://www.youtube.com/watch?v=UYt_dVUZhdQ" />
        <div className="information-card-title">Mercer Actuarial Graphs</div>
        <p><span className="orange-text">1999 Mercer, Croydon</span></p>
        <p>Actuaries are very protective of their spreadsheets and yet the sales team frequently demand access to their projections
           during their sales pitches. I was asked to try and find an accommodation between these two competing demands and this solution
           illustrates both my creative thinking and skills with Visual Basic for Applications (VBA). The solution was to create a
           virtual Excel object in memory on a Windows Server, taking sales information from a website input, processing the information 
           using accurate actuarial models and delivering data and Excel graphics back to the website for the Sales Team to use in
           real time. Sadly, as the information was business confidential, no screenshots exist of the application, but this graphic shows 
           the process.</p>
        <img alt="excel" onClick={() => onImageClick("./assets/Actuarial-Spreadsheet.png")} src="./assets/Actuarial-Spreadsheet.png" className='website-demo'></img>
        <div className="information-card-title">Electric Ordo</div>
        <img alt="carol-hunt" onClick={() => onImageClick("./assets/churchdates1.png")} src="./assets/churchdates1.png" className='leftwrap-image'></img>
        <p><span className="orange-text">1998-PRESENT Church of England</span></p>
        <p>Each year I compile a calendar of Bible Readings (as set by the CofE and Roman Catholic Churches) for the benefit of myself and other clergy. This is an task requiring
           considerable data manipulation skills as data is drawn from other websites, xml files and csv text files. This data is
           then collated and reworked into the RFC 5545 standard for iCalendar format which can be imported into Google, Outlook or
           Mac iCal. Widely used across the Church of England, I won an innovation award from the Church Times for this work in 2007.
        </p>
        
        <div className="information-card-title">College of the Resurrection Library</div>
        <p><span className="orange-text">2000 Church of England</span></p>
        <img alt="mirfield" src="./assets/mirfield.jpg" className='website-demo'></img>
        <p>Prior to my building and project management of this development, a manual system resulted in a number of
          'missing' books and no effective way of tracking who had which book. Working with key stakeholders including students, 
          Fr Nicolas the College Librarian and College Lecturers, I designed and implemented a a PHP / MySQL solution for them, saving
          the College in excess of £7.5k. I was gratified to see that 16 years later, the library system was still in use!</p>
          <div className="information-card-title">Primary School Library System</div>
        <p><span className="orange-text">2020 Bickleigh Down Primary School</span></p>
        <p>The client required the migration of data from a proprietary database system which I was able to decode and
           import before building a Java Desktop Application to enable staff and children from 4-11 years old to choose their school library
           books and be guided to others based on their reading preferences, reading abilities and teacher guidance. This required
           considerable work on the UI/UX to ensure children could use it and was based upon an Agile Development Approach guided by
           end-user feedback and user stories, often working with and observing children's interactions with the application.
        </p>
      <PDFViewer pdfFile={schoolLibraryDoc} />
      <div className="information-card-title">Christmas Carol Hunt</div>
        <p><span className="orange-text">2020 Church of England</span></p>
        <p>Written for the Church during the locked-down Christmas 2020 season, when Carol Services were prohibited,
           this Android Application written in Java enabled families in their bubbles to walk around
           their locality, scan a barcode on their phone and then hear the audio of a bible reading by a school child and join in with a
           Christmas Carol recorded by a Choir. It was adopted and localised in nearly 100 parishes across
           the Church of England. </p>
        <img alt="carol-hunt" onClick={() => onImageClick("./assets/Christmas-Carol-Hunt.png")} src="./assets/Christmas-Carol-Hunt.png" className='website-demo'></img>
    </>
    )

}

export default Dev;