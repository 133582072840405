import React from 'react';

function CV() {

    return (
        <>
<h2>Simon Phillip Rundell</h2>
<p>Web Developer Plymouth, Devon, PL7 4BJ, UK</p>
<p>07976802123&nbsp; &nbsp;|&nbsp; &nbsp;<a href="mailto:simon@rundell.org.uk">simon@rundell.org.uk</a>&nbsp; &nbsp;|&nbsp; &nbsp;<a href="http://www.simonrundell.com">www.simonrundell.com </a></p>
<p>GitHub URL&nbsp; &nbsp;|&nbsp; &nbsp;LinkedIn URL</p>
<p>Experienced developer proficient in creating dynamic and user-friendly web applications as per key requirements and technical specifications. Skilled in HTML, CSS, JavaScript, PHP and various frameworks such as React and Node.js to design high-quality, responsive websites tailored to client needs. Articulate communicator and proactive team player; adept at coordinating actively with clients, team members, and senior leadership to determine key requirements and adapt strategies accordingly. Strong problem-solving skills and a commitment to staying updated with the latest web technologies and delivering seamless digital experiences that exceed client expectations. Ready to contribute expertise to drive success in a dynamic environment.</p>
<h3>Areas of Expertise</h3>
<ul>
<li>Web Design &amp; Development</li>
<li>Systems &amp; Mobile Development</li>
<li>Systems Administration</li>
<li>Application Development</li>
<li>Client Engagement &amp; Service</li>
<li>Reporting &amp; Documentation</li>
<li>Project Management</li>
<li>Cross-functional Coordination</li>
<li>Relationship Building</li>
</ul>
<h3>Technical Proficiencies</h3>
<p><strong>Development Technologies:</strong> JavaScript (React, Node.js), VBA, SQL, PHP (Laravel), HTML, CSS, B4x (Mobile Development)</p>
<p><strong>Development Tools:</strong> GitHub (Source Control), Scrum, Figma, Active Directory</p>
<p><strong>Content Management Systems (CMS):</strong> WordPress, Typo3, Astro</p>
<p><strong>Database Management:</strong> MySQL, MS SQL-Server</p>
<p><strong>Other Programming Languages:</strong> Java, Redux, JSX, Perl</p>
<p><strong>Systems Administration:</strong> Linux, Windows, Server, and Desktop Environments</p>
<p>Productivity Tools &amp; Design Applications: MS Office (Word, Excel) Adobe (Photoshop, Premiere and After Effects)</p>
<h3>Professional Experience</h3>
<p><strong>Senior Developer, CodeMonkey Design Ltd, Plymouth 2017 &ndash; Present</strong></p>
<p>Deliver client-centric solutions as per key requirements and technical specifications. 
Conduct thorough code reviews to ensure code quality, maintainability, and adherence to coding standards. 
Manage a current portfolio comprised of eight clients.</p>
<p><i>Notable Key Contributions:</i></p>
<ul>
    <li>Develop, manage, and design websites for various clients, spanning from Churches to Nightclubs and Fitness Clubs.</li>
    <li>Administer Linux Servers running Apache, Nginx, PHP, and MySQL for hosting purposes.</li>
    <li>Create websites and plugins using WordPress (ACF, PHP, ReactJS, MySQL), CMSs like Typo3, Astro, and Node.js.</li>
    <li>Generate and execute projects based on Figma designs.</li>
</ul>
<p><strong>Contract Developer, Propriotec Ltd, Exeter (8-Week Fixed Term) 2023</strong></p>
<p>Designed scalable and robust software that met key requirements. Collaborated within Scrum Team framework, 
utilising GitHub for seamless source control and project management.</p>
<p><i>Notable Key Contributions:</i></p>
<ul>
<li>Enhanced global trading accessibility by delivering innovative software to For-Ex and Crypto traders worldwide.</li>
<li>Spearheaded security enhancements for NodeJS backend server, fortifying system integrity and resilience.</li>
<li>Transformed front-end user experience with ReactJS, amplifying interactivity and engagement.</li></ul>
<p><strong>Vicar, Church of England, Plymouth 2012 &ndash; 2023</strong></p>
<p>Led a group of faith communities on the northern edge of the city, 
necessitating effective communication skills and relational abilities. 
Developed innovative approaches to outreach and social communication, 
integrating social media (via Mailchimp) and administrative systems. 
Possessed good knowledge of UI development and usability requirements.</p>
<p><i>Notable Key Contributions:</i></p>
<ul>
    <li>Generated quality insights from data sourced from financial, local, and statutory outlets to support mission initiatives.</li>
    <li>Designed, managed, and deployed IT infrastructure to operate the Parish and its staff and volunteers, encompassing:</li>
    <li>Managed Primary School IT Systems with 50+ users, implementing Active Directory, GPOs, Security &amp; Backup.</li>
    <li>Designed GDPR-compliant data solutions for Android Phones and Desktop PCs using Java and B4X.</li>
    <li>Developed Office 365 Automation (VBA) &amp; WordPress Plugins (PHP, ReactJS, JSX, Redux &amp; JavaScript).</li>
    <li>Demonstrated expertise in data manipulation and transfer, particularly with JSON, XML, and REST APIs; proficient in manipulating data imports from CSV and other flatfile structures.</li>
    <li>Developed applications for the Desktop in Java as a frontend to MySQL and SQL Server served data.</li>
</ul>
<h3>Additional Experience</h3>
<p><strong>Senior Software Engineer (Contract), Mercer, Croydon</strong></p>
<p>Provided software development and Systems Analysis for the actuarial industry, 
    catering to blue chip clients like IBM, with a focus on Y2K. 
    Conducted testing on legacy systems to ensure Y2K Compliance; 
    developed software in Java, Visual Basic for Applications (Microsoft Office), and Perl, 
    integrated with MS SQL-Server.</p>
<p><i>Notable Key Contributions:</i></p>
<ul>
    <li>Wrote complex reporting applications and objects for direct integration into Word and Excel reports.</li>
    <li>Generated custom reports from intricate data systems utilising graphical and tabulated data.</li>
    <li>Administered Windows NT/2000 Servers and MS SQL-Servers for Systems Administration.</li>
    <li>Designed deployment solutions for new Desktop PCs.</li>
</ul>
<p><strong>Nursing Information Systems Manager, Hastings and Rother NHS Trust, Hastings</strong></p>
<p>Managed the tender and implementation of a &pound;1M Nursing Information System (Crescendo) following EU Procurement rules.
    Handled hardware management tasks, including system upgrades and maintenance.</p>
<p><i>Notable Key Contributions:</i></p>
<ul>
    <li>Administered Windows NT Servers for Crescendo, overseeing the implementation of barcode scanners in ward areas.</li>
    <li>Created interfaces between Crescendo and legacy hospital IT Infrastructure, resulting in NHS savings exceeding &pound;10K.</li>
    <li>Oversaw the computer network across WAN/LAN configuration, migrating from Novell Netware to TCP/IP Protocol.</li>
</ul>
<p><strong>Nursing Advisor, CHC Ltd, London and Edinburgh</strong></p>
<p><i>Notable Key Contributions:</i></p>
<ul>
    <li>Designed and deployed Nursing Information Systems, Radiology Information Systems, and Hospital-wide Business Intelligence Systems.</li>
    <li>Attended industry conventions and conferences by speaking, demonstrating systems, and organising CHC&rsquo;s stand.</li>
</ul>
<h3>Education &amp; Qualifications</h3>
<p><strong><u>Master's Degree in Digital Theology (with Merit)</u></strong><br></br>University of Durham, Durham</p>
<p><strong><u>Bachelor's Degree in Theology (2:1)</u></strong><br></br> University of Leeds, Leeds</p>
<p><strong><u>Bachelor's Degree in Health and Social Policy (Non-Honours degree)</u></strong><br></br> University of North London, North London</p>
<h3>Professional Certifications</h3>
<p>Microsoft Certified Solutions Developer Qualification (Expired)</p>
<p>PRINCE2 Project Management Qualification.</p>
<h3>Languages</h3>
<p>English (Native)</p>
        </>
    )

}

export default CV;