import React from 'react';

function Lightbox({ img, onClose }) {
    return (
      <>
          <img alt="lightbox" src={img} className='lightbox-image' />
          <div onClick={onClose} className="close-button">&times;</div>
      </>
    );
  }

export default Lightbox;