import React, { useState } from 'react';
import './App.css';
import CV from './components/cv';
import Dev from './components/dev';
import Admin from './components/admin';
import About from './components/about';
import Lightbox from './components/lightbox';


function App() {

  const [overlayOn, setOverlayOn] = useState(false);
  const [cardTitle, setCardTitle] = useState('');
  const [lightboxVisible, setLightboxVisible] = useState(false);
  const [lightboxImage, setLightboxImage] = useState('');

  const handleLightbox = (img) => {
    setLightboxImage(img);
    setLightboxVisible(true);
  }; 

  const handleCVClick = () => {
      setCardTitle('Curriculum Vitae');
      setOverlayOn(true);
  }

  const handleDevClick = () => {
    setCardTitle('Development Portfolio');
    setOverlayOn(true);
}

  const handleAdminClick = () => {
    setCardTitle('Systems Administration');
    setOverlayOn(true);
  }

  const handleAboutClick = () => {
    setCardTitle('About');
    setOverlayOn(true);
}

  const handleClose = () => {
      setOverlayOn(false);
  }


  return (
    <div className="App">
      { overlayOn && (
        <div className="overlay-background" onClick={() => setOverlayOn(false)}></div>
      )}
      {lightboxVisible && (
        <>
        <div className="overlay-background-lightbox" onClick={() => setLightboxVisible(false)}></div>
        <div className={`lightbox-modal ${lightboxVisible ? 'visible' : ''}`}>
        <Lightbox img={lightboxImage} onClose={()=> setLightboxVisible(false)}/>
        </div>
        </>
        )}

    <div className={`information-card ${overlayOn ? 'visible' : ''}`}>
      <div className="modal-content">
        <span className="close-button" onClick={handleClose}>&times;</span>
        <div className="information-card-title">{cardTitle}</div>
        {cardTitle === "Curriculum Vitae" && <CV />}
        {cardTitle === "Development Portfolio" && <Dev onImageClick={handleLightbox} />}
        {cardTitle === "Systems Administration" && <Admin />} 
        {cardTitle === "About" && <About onImageClick={handleLightbox} />}
      </div>
    </div>

      <div className="bottom-right-text"><span className='light-grey-text'>Simon Rundell</span> <span className='grey-text'>Portfolio</span></div>
      <div className="overlay-text">React, Node, HTML, CSS, VBA, MySQL, B4x and much more</div>
      <div className="button-container">
          <button className="button" onClick={handleDevClick}>Development Portfolio</button>
          <button className="button" onClick={handleAdminClick}>Systems Administration</button>
          <button className="button" onClick={handleCVClick}>Curriculum Vitae</button>
          <button className="button" onClick={handleAboutClick}>About</button>
      </div>
    </div>  
  );
}

export default App;
