import React from 'react';
import Youtube from './youtube';

function About({onImageClick}) {

    return (
        <>
        <p>About</p>
        <img alt="priest" onClick={() => onImageClick("./assets/lincolns_baptism.jpg")} src="./assets/lincolns_baptism.jpg" className='leftwrap-image' style={{ width: '30%' }}></img> 
        <p>Simon Rundell is an IT professional with a wealth of life and technical experience. Having
            trained as a Registered Nurse in the 1980s, he moved into Healthcare IT and was a senior
            manager at a major NHS Trust. He worked for a period as an IT Contractor in preparation for 
            impending Y2K crisis he pursued a vocation to the Church of England Priesthood and trained at
            the College of the Resurrection at a monastery in Yorkshire before serving as a Vicar in the Dioceses of
            Portsmouth and latterly the Diocese of Exeter. During that time he maintained his technical
            skills and continued to manage and develop IT solutions for the wider church. Having returned from
            full-time ministry he remains a Priest.</p>
        <p>
            He has written three books published by Canterbury Press on Sacramental Mission and work with
            children. <a href="https://www.amazon.co.uk/stores/Simon-Rundell/author/B005GVNH4E">Amazon Link</a>
            <div className="book-container">
  <div className="book">
    <img src="./assets/711V8096TmL.jpg" alt="Creative Ideas for Alternative Sacramental Worship"></img>
    <p className="title">2010</p>
  </div>
  <div className="book">
    <img src="./assets/714GxuDuymL.jpg" alt="Creative Ideas for Sacramental Worship with Children"></img>
    <p className="title">2011</p>
  </div>
  <div className="book">
    <img src="./assets/51NOeJbasKL.jpg" alt="Creative Ideas for Frontline Evangelism with Young People"></img>
    <p className="title">2013</p>
  </div>
</div>

        </p>
        <p>Married with three adult children and a grandchild, in 2012 he appeared on BBC's Mastermind, answering questions on <i>Monty Python</i>. He came second.</p>
        <Youtube url="https://www.youtube.com/watch?v=2kxWAsmBYJ0" />            
        <p>His principle hobby is ballroom and latin dancing with his wife, Lou, in which he has competed at Blackpool at
           a national level. He is training to be a qualified dance teacher, just for fun.</p>
        <img alt="dancing" src="./assets/Supadance_Apr17_034.jpg" onClick={() => onImageClick("./assets/Supadance_Apr17_034.jpg")} className="website-demo"></img>
</>
    )

}

export default About;